import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { routes } from '../helpers/routes'

const IndexPage: NextPage = () => {
  const joinPath = routes.joinIndex
  const router = useRouter()

  useEffect(() => {
    if (router && joinPath) {
      router.push(joinPath)
    }
  }, [router, joinPath])

  return null
}

export default IndexPage
